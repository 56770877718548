.container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    visibility: visible;
    opacity: 1;
    background-color: #7C7267!important;
   


}

.imgCont {
    height: 250px;
    width: 600px;
    position: relative;
}

.imgCont > img {
    height: 100%;
    width: 100%;
   
object-fit: contain;

}



@media only screen and (max-width: 500px) {


    .imgCont {
        height: 230px;
        width: 80%;
    }

    .imgAbso > img {
        width: 263.44px;
        height: 260.11px;
    
    }






}

@media only screen and (max-height: 400px) {


    .imgCont {
        height: 150px;
        width: 240px;
    }

    .imgAbso > img {
        width: 192.24px;
        height: 189.81px;
    
    }






}


