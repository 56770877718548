.image-carousel {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #222;
  }
  
  .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.5s ease-in-out;
    opacity: 0.9;
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1rem;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    z-index: 10;
   
  }
  
  .carouselLeft {
    left: 20px;
    cursor: pointer;
  }
  
  .carouselRight {
    right: 20px;
    cursor: pointer;
  }
  