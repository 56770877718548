.container {
    width: 100%;
    height: auto;

    padding: 8vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:  #e9e6e0 ;
    visibility: hidden;

}


.wrapper {
    height: auto;
    width: 95%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 3.3rem;
    flex-wrap: wrap;
    padding: 2vh 0;
}


.box {
    position: relative;
    width: 350px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    transition: all 0.2s ease-in-out;
    gap: 1rem;

    padding-bottom: 2vh;
  
 
}


/*
  paper fold corner
*/

.box:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-style: solid;
    border-width: 0 50px 50px 0;
    border-color: #ddd #34495E;
    transition: all ease .5s;
    z-index: 9;
  }

  .box:after {
    content: "";
    position: absolute;
    z-index: 11;
    top: 6px;
    right: 6px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 10px; /* Adjust the values as needed for the size of your arrow */
    border-color: transparent transparent transparent transparent; /* Adjust the color as needed */
    transition: all ease .5s;
  }

  .box:hover:after {
    border-color: transparent transparent transparent #fff; 

  }
  
  /*
    on li hover make paper fold larger
  */
  .box:hover:before {
    border-width: 0 100px 100px 0;
    
    border-color: #eee #34495E;
  }


.box:hover {

    cursor: pointer;
    
 

}

.box > h5 {
    text-align: center;
    width: 80%;
    font-size: 24px;

}

.box > p {
    width: 90%;
    font-size: 17px;
    text-align: left;
    font-family: 'Inter', sans-serif;
    
}


.boxImg {
    width: 100%;
    height: auto;
    background-color: #fff;
}

.boxImg > img:hover {

    opacity: 0.8;
    
}

.boxImg > img {
    width: 100%;
    height: auto;
    object-fit: contain;
    transition: all 0.2s ease-in-out;
}






@media (max-width: 1000px) {


    .box > h5 {


        font-size: 21px;


    }


    .box > p {
       
        font-size: 15px;
    }
    




}