
.container {
    font-size: 50px;
    font-weight: 600;
    color: #3f7d3c;
    height: auto;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 2vh 0;

    margin: 7vh 0;
    background-color:  #e9e6e0 ;
   
    overflow-x: scroll;

}








.wrapper {
    width: auto;
    height: auto;
    
    display: flex;
   
    gap: 1vw;
    transform: translateX(0);

        

    
    transition:  all 0.4s ease-in-out;
  
    
   
    
   
    
 }




 




.imgDiv {

   

    height: 500px;
    width: 32vw;
    position: relative;
    overflow: hidden;
    float: right;
   
   

}




.imgDiv > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition:  all 1s ease-in-out;
   
    
    
   

}

 


.buttonDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 2vh 0;
}


.buttonDiv > div:hover {

    cursor: pointer;
    color: #fff
}



@media (max-width: 1180px) {



    .imgDiv {

   

        height: 300px ;


    }

}



@media (max-width: 400px) {

    .wrapper {
      
    gap: 0;
       
    }

    .imgDiv {

        width: 100vw;

        height: 250px ;


    }




}


