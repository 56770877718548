.container {
    width: 100%;
    height: auto;
    padding: 5vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #e9e6e0;

}

.wrapper {
    width: 70%;
    height: auto;
    padding: 2vh 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
}


.row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    width: 100%;
}


.row:nth-child(even) {
    flex-direction: row-reverse;
}

.txtBox {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
}

.txtBox > p {

    width: 80%;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 200;

}

.txtBox > h3 {
    font-size: 25px;
}

.imgBox {
    height: auto;
    width: 50%;
}

.imgBox > img {
    width: 100%;
    height: auto;
    object-fit: contain;
}





   


   






@media (max-width: 1050px) {


  .imgBox, .txtBox {
    width: 100%;

    gap: 1rem;

  }


  .txtBox > p {
    width: 100%;
    font-size: 16px;


  }

  .wrapper {
    width: 90%;
}


    .row {
        flex-direction: column;
    }

    .row:nth-child(even) {
        flex-direction: column;

    }





}


@media (max-width: 600px) {


.txtBox > p {
    width: 100%;
    font-size: 14px;




  }

  .txtBox > h3 {
    font-size: 22px;
}

}