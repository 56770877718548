.container {
    position: absolute;
    top: 0;
    left: 0;
    height: 120vh;
    width: 100%;
   
    background-color: rgba(0, 0, 0, 0.2);
    background-size: cover;
    background-position: center;
    background-blend-mode: darken;
    display: flex;
    padding-top: 10vh;
    padding-bottom: 5vh;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.topBar {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

}

.section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  
  
  }

  
  .section > h1 {
    color: #fff;
    font-size: 50px;
    padding-top: 3vh;
  
  
  }
  
  .section >  img {
    height: 100px;
    width: auto;
  }

.wrapper {
    width: 30%;
   height: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 2vh 0;
    gap: 3px;
    border-radius: 20px;
    
}



.wrapper>h2 {
    color: #222;
    text-align: center;
    margin: 1.5rem 0;
    font-size: 32px;
    line-height: 120%;
}

.wrapper>form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.wrapper > form>input {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid #333;
    font-size: 18px;
    margin-bottom: 0.5vh;
}

.wrapper >form>label {
    align-self: center;
    width: 70%;
    cursor: pointer;
    transition: 150ms all;
   padding: 0.2rem;
    border: 1px #222 solid;
    margin-top: 1vh;
   
}

.wrapper >form> label:hover {
    color: green;
    border: 1px green solid;
}

.wrapper >form>button {
    outline: none;
    background-color: #222;
    color: #fff;
  
    padding: 0.5rem 1.25rem;
    font-size: 18px;
    cursor: pointer;
    border: 1px solid transparent;
}



.wrapper >form>button:hover {
    background-color: #444;
    border-color: #222;
  color: #fff;
}

.wrapper >form>p {
    text-align: center;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wrapper >form>p>a {
    text-decoration: none;
    font-size: 17px;
    color: #888;
    margin-top: 0.25rem;
}

.desc {
    width: 100%;
    height: 165px;
    margin-top: 1vh;
    font-size: 15px;
}

.sign:hover {
    color: limegreen;
}


.labels {
    text-align: center;
}

.error {
    height: 70px;
    width: 250px;
    text-align: center;
    border-radius: 20px;
    line-height: 1.5rem;
    padding: 0.75rem 1.5rem;
    background-color: #f00;
    color: #fff;
    position: fixed;
    top: 5rem;
    right: 5rem;
    font-weight: 500;
}



/* RESPONSIVE */

@media only screen and (max-width: 1192px) {

    .wrapper {
        width: 70%;
    }

   

    .section > h1 {
        color: #fff;
        font-size: 40px;
        padding-top: 3vh;
        
      
      
      }
      
      .section >  img {
        height: 100px;
        width: auto;
      }
  

  
}

@media only screen and (max-width: 600px) {

    .container {
        
        height: 140vh;
    }

    .wrapper {
        width: 80%;
    }


    .topBar {
        width: 90%;
    }

    .section {
        gap: 0.2rem;
        flex-direction: column;
    }

    .wrapper > form>input {
        width: 80%;
        font-size: 16px;
    }

    .wrapper>h2 {
        font-size: 22px;
    }
    
    .section > h1 {
        color: #fff;
        font-size: 22px;
        padding-top: 3vh;
        text-align: center;
        
      
      
      }

      .desc {
        width: 90%;

      }
      
     .wrapper > form > p {
        width: 80%;

      }


}