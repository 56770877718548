.coming {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5vh;
    background-color:  #e9e6e0 ;
}


.coming > h1 {
    text-align: center;
    width: 80%;
}