.dropdown {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
 
 
    background-color: transparent;
   

  }

  
  .form {
    display: flex;
    background: transparent;
    position: relative;
    width: 100%;
  }
  
 

  
  .chevron {
 
    transform: rotate(0deg);
    transition: transform 0.2s color 0.05s;

    font-weight: 200;
  }
  
  .chevron.active {
    transform: rotate(180deg);
  }
  

  .button {
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    background: transparent;
    gap: 0.4rem;
   
    font-family: 'NewYork', "sans-serif";
    cursor: pointer;
   font-size: 14px;

    width: 100%;
    transition: color 0.1s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  
    
    font-weight: 100;
 
    
  }

 

  .dropdownList {
   
    position: absolute;
    display: flex !important;
    flex-direction: column;
    align-items: left !important;
    padding-top: 15px !important;
    padding-right: 25px !important;
    padding-left: 0 !important;
   
   
    top: 26px;
   left: -24px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 500ms, visibility 500ms;
    transition: opacity 500ms, visibility 500ms;
    justify-content: space-around !important;
    background-color: #7C7267 !important;
    z-index: 999;
    width: auto;
    height: auto;
    
   
   

    
  }
  .dropdownList > li  {
padding-left: 23px;
padding-bottom: 2vh;
white-space: nowrap;
color: black !important;
list-style-type: none;


  }
  

  .dropdownList > li > a {
    color: #fff !important;
    text-decoration: none;
    transition: color 0.4s ease-in-out;
  }

  .dropdownList > li > a:hover {
    color: #777;



  }
  
  
  .dropdownList.show {
   
    opacity:  1 !important;
    background-color: #fff;
    visibility: visible;

  

  }
  
  .dropdown > ul > li {
    font-weight: 200;


  }
  
  .dropdown > ul > li:hover {
    color: #999;
    transition: all 0.5s ease;
  }


  @media (max-height: 500px) {


    .dropdownList {

      gap: 0.8rem!important;


    }

 


  }


  @media (max-width: 1000px) {

    .dropdownList {

      left: 0px;
      width: 100% !important;
      height: auto;
      align-items: center;
     justify-content: center;
     background: #555 !important;
     gap: 3rem;
      padding: 1vh 0 !important;
      list-style-type: none;
     

    }



    .button {

      padding-left: 22px;
      color: #222 !important;


    }

    

    .dropdownList > li  {
      padding-left: 0vh;
      padding-right: 0vh !important;
      padding-bottom: 0vh;
     
      padding-left: 0;
      white-space: nowrap;
  
      
      
        }

        .dropdownList > li  > a {
          
          color: #fff !important;

        }
      

  }