.container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10vh 0;
    font-weight: 500;
    font-size: 18px;
  
   background-color:  #e9e6e0 ;
    
}

.wrapper {
    width: 70%;
    height: auto;
    display: flex;
    padding: 2vh 0;
    align-items: center;
    justify-content: center;
    gap: 2rem;

}


.photo {
    height: 500px;
    width: 50%;
    margin-bottom: 4vh;


}

.photo>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.desc {
    width: 50%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.desc > p {
    width: 80%;
    font-family: 'Inter', sans-serif;
    font-weight: 200;
}


.desc > h3 {
    font-size: 25px;
}


.extraText {
    display: flex;
    width: 80%;
    height: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    font-weight: 200;
}

.readMore {
    color: rgb(46, 51, 25);
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 2vh;
    gap: 1rem;
}











@media (max-width: 1100px) {


    .container {
       font-size: 16px;

        padding: 4vh 0;
    }


    .wrapper {
        flex-direction: column !important;
        
        width: 90%;
    }



    .photo {
        width: 100%;
        display:   none;


    }


  

    .desc > p {
        width: 100%;


    }
    

    .desc {
        width: 100%;
    }


    .extraText {


        flex-direction: column;
    }




}


@media (max-width: 600px) {

    .container {
        font-size: 14px;


    }


    .desc > h3 {
        font-size: 22px;
    }




}