.outerContainer {
    width: 100%;
    position: fixed;
    z-index: 99;
}


.container {
    width: 100%;
    height: auto;

    padding: 0.5vh 0;
   
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-bottom: transparent;


    gap: 0.3vh;
 
    transition: all 0.6s ease-in-out;
  

}

.logo{

    width: 150px;
    height: 80px;
    transition: all 0.2s ease-in-out;
}

.logo > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.wrapper {
    width: 90%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 0.8s ease-in-out;
  

}




.menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    transition: transform 0.2s ease-in-out;

}

.menu>a {
    text-decoration: none;
    
   transition: all 100ms ease-in-out;
    font-size: 14px;
    font-weight: 200;
}




.menu > div{
  
    font-size: 14px;
    transition: all 100ms ease-in-out;
    font-weight: 200;
}

.menu>a:after {
    content: "";
    display: block;
    height: 3px;
    margin-top: 1vh;
    width: 0;
    background: transparent;
    transition: width 0.4s ease, background-color 0.5s ease;
  }


  .menu > div:after {
    content: "";
    display: block;
    visibility: hidden;
    height: 3px;
    margin-top: 1vh;
    width: 0;
    background: transparent;
    transition: width 0.4s ease, background-color 0.5s ease;
  }

  .menu > div:hover:after {
    width: 100%;
    background: #ffdd40;
  }
  
  .menu > p.active {
    color: #ffdd40;
    border: 1px solid #ffdd40;
    padding-bottom: 1vh;
  }

  .menu > a:hover:after {
    width: 100%;
    background: #ffdd40;
  }
  
  .menu > a.active {
    color: #ffdd40;
    border: 1px solid #ffdd40;
    padding-bottom: 1vh;
  }
  


.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    margin-top: 1vh;
    background: transparent;
  
}

.line {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin-bottom: 5px;
}


@media (max-height: 500px) {

    .container {

        
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0;
    }

    .wrapper {
        width: auto;
    }


    
    .menu.open {
        opacity: 1;
        visibility: visible;

        width: 35vh;
        gap: 0.3rem;

    }








}





@media (max-width: 1000px) {


    .container {
        padding: 1vh 0;
    }

   

    .navbar {
        width: 100%;
        padding-bottom: 1vh;
    }

 
    .menu {


        display: flex;
        flex-direction: column;
        position: absolute;
        align-items: center;
        justify-content: center;
        top: 70px;
        gap: 1.5rem;
        width: 100%;
        background-color: #fff;
        color: black !important;
        padding: 1vh 0;
        z-index: 999;
        opacity: 0;
        visibility: hidden;
        transition: all 0.1s ease-in-out;
    }


    .menu.open {
        opacity: 1;
        visibility: visible;

    }


    .menu > div {
        height: auto;
        width: 100%;
        padding: 0;
        margin: 0;
       
    }

    .menu > a {
        color: black !important;
    }

    .hamburger {
        display: flex;
        background-color: transparent;
      }


      .logo{

        width: 120px;
        height: 60px;

      }













}