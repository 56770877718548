.container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5vh 0;
    margin-bottom: 2vh;
    background-color: #e9e6e0;
}

.wrapper {
    width: 70%;
    height: auto;
    display: flex;
    padding: 2vh 0;
    align-items: center;
    justify-content: center;
    gap: 2rem;

}


.wrapper > img {
    width: 100%;
    height: auto;
    object-fit: contain;
}


@media (max-width: 1000px) {


    .container {
        padding: 2vh 0;
    }


    .wrapper {
        flex-direction: column;
        width: 90%;
    }



  




}