.container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5vh 0;
    background-color:  #e9e6e0 ;
}

.wrapper {
    width: 70%;
    height: auto;
    display: flex;
    padding: 2vh 0;
    align-items: center;
    justify-content: center;
    gap: 3rem;

}

.box1 {
    width: 30%;
    height: auto;
}

.box2{
    width: 50%;
    height: auto;
}

.box1 > img, .box2 > img {
    width: 100%;
    height: auto;
    object-fit: contain;
}


@media (max-width: 1000px) {


    .wrapper {
        
        width: 90%;
    }



  




}