.container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 0.2rem;
    padding: 3vh 0;
    background-color: #7C7267 ;
    color: white;
}

.wrapper {
    width:  80%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;

}

.wrapper > .item {
  
    font-size: 20px;
}


.item > a {
    color: white;
}



@media (max-width: 600px) {


    .wrapper > .item {
  
        font-size: 17px;
    }



}