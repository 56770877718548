.container {
    height: 100vh;
    position: relative;
    z-index: 90;
}

.container > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.abso {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    top: 0%;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9;
}

.abso > h1 {
    font-size: 75px;
    width: 80%;
    color: #fff;
    font-weight: 600;
    text-align: center;
}


.abso > p {
    font-size: 28px;
    color: #fff;
    text-align: center;
    width: 80%;
}


.scrollIcon {
  position: absolute;
  z-index: 9;
    bottom: 20px; /* Adjust the distance from the bottom as needed */
    left: 50%;
     color:#fff;
    transform: translateX(-50%);
    font-size: 1rem; /* Adjust the font size as needed */
    animation: blink 1.5s infinite;
  }
  
  @keyframes blink {
    0%, 50%, 100% {
      opacity: 0.3;
    }
    25%, 75% {
      opacity: 1;
    }
  }



@media (max-height: 500px) {

    .container {
        height: 100vh;
    }






}



@media (max-width: 1181px) {


   


    .abso > h1 {
        font-size: 45px;
    }
    .container {
        height: 80vh;

    }



}





@media (max-width: 1000px) {



  

  


    .abso > h1 {
        font-size: 45px;
    }

    .abso > p {
        font-size: 25px;
       
    }





}


@media (max-width: 800px) { 
    .abso {
        top: 0%;
    }




}






@media (max-width: 600px) {


  

    .abso > h1 {
        font-size: 30px;

    }

    .abso > p {
        font-size: 18px;
       
    }




}






