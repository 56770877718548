
* {
  margin: 0;
  padding: 0;
  
}

@font-face {
  font-family: 'NewYork';
  src: url('./fonts/NewYork.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Made';
  src: url('./fonts/Made.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


/* Importing Monoid Regular */
@font-face {
  font-family: 'Monoid';
  src: url('./fonts/Monoid-a0-a1-a3-al-ad-aa.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Importing Monoid Bold */
@font-face {
  font-family: 'Monoid';
  src: url('./fonts/Monoid-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Importing Monoid Italic */
@font-face {
  font-family: 'Monoid';
  src: url('./fonts/Monoid-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}


@font-face {
  font-family: 'Monoid';
  src: url('./fonts/Monoid-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Monoid';
  src: url('./fonts/Monoid-Retina.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/* Importing Monoid Bold Italic */




body {
  margin: 0;
  padding: 0;
  font-family: 'NewYork', 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(83, 64, 52);
  background-color: #7C7267;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Track */
::-webkit-scrollbar {
  width: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Track on hover */
::-webkit-scrollbar-track:hover {
  background-color: #f1f1f1;
}

/* Handle when scrolling */
::-webkit-scrollbar-thumb:active {
  background-color: #555;
}

/* Track when scrolling */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}




.tab-content {
  width: 100%;
}


.modal::-webkit-scrollbar {
  display: none;
  position: relative;
}


.surrounder {
  position: relative;
  width: 100%;
  overflow: hidden;
}


