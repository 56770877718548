.container {
    height: 700px;
    width: 100%;
    position: relative;
    cursor: pointer;
}


.container > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.abso {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.abso > h3 {
    font-size: 60px;
    font-weight: 600;
    color: white;
    
}







@media (max-width: 1000px) {



.abso > h3 {
    font-size: 45px;
}




}



@media (max-width: 600px) {



    .abso > h3 {
        font-size: 30px;
    }
    
    
    
    
    }