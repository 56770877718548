/* list property */

.container {
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding-bottom: 6vh;
    padding-top: 15vh;
    flex-direction: column;
    background-color:    #e9e6e0!important;
    overflow: hidden;
}
    



.topDiv {
    width: 100%;
    height: 80vh;
    overflow: hidden;
    position: relative;
    background-color: black;
}

.topDiv > img {
    width: 100%;
    height: 100%;
    opacity: 0.7;
    object-fit: cover;
}

.abso {
    position: absolute;
    top: 40%;
    width:  100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.abso > .specialP {
    font-size: 65px;
 
    color: #fff;
}


.middleDiv {
    height: auto;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: 1.4rem;
    padding: 6vh 0;
}

.middleDiv > .specialP{

    font-size: 34px !important;
   
    color: black !important;
        font-weight: 200;


}

.middleDiv > p {
    font-size: 20px;
    color: #444;
    font-weight: 200;

    
    
}


.topDesc, .eventDesc {
    font-family: 'Inter', sans-serif;
}

.contacts {
    display: flex;
    gap: 4rem;
}

.contacts > span {
    font-size: 24px;
    
}


.eventsDiv {
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 6vh;
    gap: 2rem;

}

.eventsDiv > .specialP {
    font-size: 34px;
    margin-bottom: 2vh;
    padding-top: 2vh;
    color: black;
    font-weight: 200;
}

.row {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
   gap: 3rem;
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    transform: translateX(-35%);
    overflow: hidden;
    opacity: 0;
    transition: all 0.6s ease-in-out;
}

.row:nth-child(even) {
    flex-direction: row-reverse;
    transform: translateX(35%);
}

.row.appear {
    opacity: 1;
    transform: translateX(0);


}

.imgCont {
    height: 400px;
    width: auto;
     overflow: hidden;

   
}

.imgCont > img {
    width: 100%;
    height: 100%;
 object-fit: contain ;
    
}

.textSection {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    align-items: left;
    width: 55%;
    padding: 2vh 1.5vw;
    font-weight: 200;


}

.textSection > .specialP {
    color: black;
    font-size: 30px;
    text-align: left;
    font-weight: 200;

}

.textSection > p{
    color:#444;
    font-size: 19px;
    width: 90%;
    text-align: left;
    font-weight: 200;
    

}

.date {
    font-size: 21px !important;
font-weight: 400 !important;
    margin-bottom: 0.6vh;
    color:#444;
    
}


.sliderContainer {
    width: 90%;
    height: 300px;
}


.contactForm {
   
   
    height: auto;
    width: 100%;
  padding: 10vh 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

}

.contactFormWrapper {
    
 
    width:  35%;
    height: auto;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    background-color: #2d2c2c !important;
    flex-direction: column;
    justify-content: center;
    padding: 1.5vh 0.5vw;
    align-items: center;
     
   
    transform: translateX(-35%);
    opacity: 0;
    transition: all 0.6s ease-in-out;
   
   
}


.contactFormWrapper.appear {
    
    transform: translateX(0);
    opacity: 1;

}

.contactFormWrapper> .specialP {
    text-align: center;
    margin: 0.5rem 0;
    margin-bottom: 0.5rem;
    font-size: 38px;
    color: #444;
    font-weight: 200;
    color:  #fff7f9;
}

.contactFormWrapper> p {
    font-size:  20px;
    font-weight: 200;
   color:  #fff7f9;
    
}

.contactFormWrapper>form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding-top: 1vh;
    width: 60%;
    
}

.contactFormWrapper>form>input {
    margin-top: 1vh;
    margin-bottom: 1vh;
    font-size: 17px;
    width: 70%;
    border:0;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.09);
    color: #222;
    font-weight: 200;
}


.contactFormWrapper>form>textarea {

    height: 200px;
    width: 100%;
    font-size: 20px;
    font-weight: 200;


}



.contactFormWrapper>form>div>label {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    font-size: 19px;
    margin-right: 1rem;
}



.mess {
    width: 80%;
    
    text-align: center;
    color: #444;
    font-size: 19px;
}

.area {
    width: 300px;
    height: 150px;
   
    color: #222;
    border:0;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.09);
}

.carWrapper {
    width: 100%;
    height: 250px;

}

.contactFormWrapper button {
    margin-top: 0.5rem;
}

.artistBtn  {
    background-color: #2d2c2c !important;
    font-weight: 200;
    
   text-decoration: none;
    padding: 0.1vh 0.3vw;
    height: 50px;
    display: flex;
    font-size: 18px;
    width: 130px;
    border: 1px solid #2d2c2c;
        cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.2s all ease-in-out;
    color: #fff!important;
    margin-top: 1vh;
}


.artistBtn:hover {

    background-color: #fff !important;
    color: #222 !important;
      

}

.getBtn {
    background-color: #f3c7c7 !important;
    
   text-decoration: none;
    padding: 0.4vh 0.4vw;
    height: 7vh;
    display: flex;
    font-size: 18px;
    width: 120px;
    font-weight: 200;
        cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.2s all ease-in-out;
    color: #444!important;
  }



  


  .getBtn:hover {
    background-color: #fff !important;
    color: #222 !important;
      
     
   
 
  }

.error {
    height: 70px;
    width: 250px;
    text-align: center;
    border-radius: 20px;
    line-height: 1.5rem;
    padding: 0.75rem 1.5rem;
    background-color: #f00;
    color: #fff;
    position: fixed;
    top: 5rem;
    right: 5rem;
    font-weight: 500;
}

.eventDesc {
    font-size: 18px !important;
}

.success {
    position: fixed;
    z-index: -5;
    height: 100vh;
    width: 100%;
    text-align: center;
 font-size: 30px;
    line-height: 3.5rem;
    padding: 1.5rem;
    background-color: green;
    color: #fff;
   
    top: 0;
   
    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25); 
    
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 200ms all ease-in-out;
    opacity: 0;
}

.message {
    font-size: 30px;
    font-weight: 500;
    width: 80%;
    text-align: center;
    color: rgb(218, 126, 56);
    text-transform: uppercase;
}
  
  .success.show {
    opacity: 1;
    z-index: 999;
  }


  
.hr {
    width: 30%;
 
    
}

.hr  > hr{
    align-self: left;
    margin-bottom: 0.6vh;

}


@media only screen and (max-height: 700px) {

    .homeSpecialP {
        font-size: 35px;
     
        color: #fff;
    }

    
    .homeAbso {
     
        top: 30%;

    }

}

@media only screen and (max-width: 1100px) {

 

       .getBtn {
       
        padding: 0.4vh 0.4vw;
       height: 40px;
       width: 60px;
        font-size: 12px;
       
    }

    .contactFormWrapper button {
        margin-top: 0.2rem;
    }

   
    

       .contactFormWrapper >  p {
        font-size:13px
       }

       .contactFormWrapper> .specialP {
        font-size: 24px;
        margin-bottom: 0.2rem;
    }


 
      

    .area {
        width: 90%;
       height: 120px;
        font-size: 13px;
        
    }

    
    .contactFormWrapper>form>input {
        width: 70%;
        font-size: 15px;
    }






    
    .middleDiv > p {
        font-size: 16px;
        color: #444;
        
    }


    .eventsDiv > .specialP {

        font-size: 25px;
        text-align: center;


    }

    .row {
        flex-direction: column;

       

        gap: 0;
        
            width: 80%;
            height: auto;
    }
    .row:nth-child(even) {
        flex-direction: column;
        width: 80%;
    }
    .imgCont {
        height: 500px;
        width: 100%;
       
    }


    .imgCont >img {
        height: 100%;
        object-fit: cover;
    }

    .textSection {
        align-items: center;
        gap: 0.7rem;
        width: 90%;
        padding:  3.4vw;
    }

    .textSection > .specialP {
   
        font-size: 25px;
        text-align: center;
    
    }

    .date {
        font-size: 18px !important;
        


    }
    
    .textSection > p{
       
        font-size: 15px;
      
        text-align: center;
        
    
    }
    .eventDesc {
        font-size: 15px !important;
        text-align: center;
    }
    
}


@media only screen and (max-width: 1000px) {

    .message {
        font-size: 25px;
    }

    
    .contactFormWrapper>h2 {
        font-size: 29px;
    }

        
    .middleDiv > .specialP{

        font-size: 27px !important;
       
        color: #444 !important;
    

    }


    .contactFormWrapper> p {
        font-size: 16px;
    }
    .contactFormWrapper>form>input {
        width: 70%;
    }


    .area {
        width: 80%;
        

    }
}

@media only screen and (max-width: 900px) {

    .contactFormWrapper {
        width:  60%;
        height: 60%;
       }

   .contactFormWrapper>h2 {
    font-size: 27px;
}

.success {

    height: 190vh;



}

.artistBtn {

    font-size: 13px;

    width: 80px;
    height: 50px;
}




}


@media only screen and (max-width: 760px) {

   

    .absoClass2 {

        width: 220px;
        height: 250px;
   
     
    
        }

        .imgCont {
            height: 320px !important;
           
           
        }


        .homeSpecialP {
            font-size: 35px;
         
            color: #fff;
        }

        
.contactFormWrapper>form>textarea {

  
    font-size: 15px;
    


}




}


 











@media only screen and (max-width: 600px) {

    .message {
        font-size: 18px;
    }


    


    .middleDiv > .specialP{

        font-size: 23px !important;
       
        color: #444 !important;
    

    }

    .eventsDiv { 
        width: 90%;
    }
    .row {
        flex-direction: column;
        
            width: 100%;
    }
    .row:nth-child(even) {
        flex-direction: column;
        width: 100%;
    }

    .contactFormWrapper {
        width:  85%;
     
        padding: 0.6vh 0.5vw;
       }

    .contactFormWrapper>form>textarea{
        width: 100%;
    }
    .contactFormWrapper>form>input {
        width: 90%;
    }



    .getBtn {
       
        padding: 0.4vh 0.4vw;
       width: 40%;
        font-size: 14px;
       
    }


}


@media only screen and (max-width: 300px) {
.imgCont {
    height: 250px !important;
  
   
}

}


@media screen and (max-width: 1025px) and (max-height: 822px) {


    .imgCont {
        height: 700px;
        width: 100% !important;
    }


   

   
    
    
}


@media screen and (max-width: 1192px) and (max-height: 822px) {



   


   

   
    
    
}