.container {
    height: 100vh;
    width: 100%;
    position: relative;
    background: #222;
}


.container > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
}


.abso {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 8;
}

.abso > h3 {
    font-size: 60px;
    font-weight: 600;
    color: white;
    
}


.scrollIcon {
    position: absolute;
      bottom: 20px; /* Adjust the distance from the bottom as needed */
      left: 50%;
       color:#fff;
      transform: translateX(-50%);
      font-size: 1rem; /* Adjust the font size as needed */
      animation: blink 1.5s infinite;
    }
    
    @keyframes blink {
      0%, 50%, 100% {
        opacity: 0.3;
      }
      25%, 75% {
        opacity: 1;
      }
    }
  


    @media (max-width: 1181px) {

        .container {
            height: 80vh;
    
    
    
        }
    }    




@media (max-width: 1000px) {



.abso > h3 {
    font-size: 45px;
}




}



@media (max-width: 600px) {



    .abso > h3 {
        font-size: 32px;
    }


    .container {
        height: 80vh;




    }
    
    
    
    
    }



    
@media (max-width: 310px) {


    
    .abso > h3 {
        font-size: 25px;
    }




}