.container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
   justify-content: center;
   padding: 2vh 0;

   padding-top: 20vh;
   background-color:  #e9e6e0 ;
}

.quoteWrapper {
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 4vh 0;
    padding-bottom: 8vh;
}


.quoteWrapper > p {
    text-align: center;
    font-size: 24px;
}