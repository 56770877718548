.container {
    width: 100%;
    height: auto;
    padding: 4vh 0;
    padding-top: 22vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:  #e9e6e0 ;
    
}

.wrapper {

    height: auto;
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.top {

    height: auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
   

}


.left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
    padding: 1vh 0;
    gap: 0.8rem;
    
}

.left > h3 {
    margin-bottom: 1vh;
    font-size: 22px;
}

.left > p, .left > span{
    width: 85%;
    font-family: 'Inter', sans-serif;
    font-weight: 200;
}

.right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
   width: 50%;


    
}


.imgDiv {

    width: 400px;
    height: 350px;


}

.imgDiv > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bottom {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    padding: 2vh;
    padding-top:5vh;

    
}


.bottom > p{

    font-family: 'Inter', sans-serif;
    font-weight: 200;
    
}

.bottom > h4 {
    font-family: 'Inter', sans-serif;
    font-weight: 200;

}


@media (max-width: 1181px) {


    .container {
        padding-top: 18vh;
    }



   

    .top{
        flex-direction: column;
    }


   


    .imgDiv {

        width: 100%;
        height: auto;
    
    
    }

    .wrapper {
        width: 90%;
    }


    .right{

        width: 100%;

    }

    .left {
        width: 100%;
        padding-bottom: 4vh;
    }

  




}


@media (max-width: 821px) {



    .container {
        padding-top: 10vh;
    }

    .left {
        padding: 5vh 0;
    }





}


@media (max-width: 600px) {


    .container {
        padding-top: 13vh;
    }





}

